import toly from '../assets/images/toly.jpg'
import twitter_logo from "../assets/images/twitter.png"
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const TWITTER_CLIENT_ID = process.env.REACT_APP_TWITTER_CLIENT_ID;
const REDIRECT_URI = process.env.REACT_APP_REDIRECT_URI;

function FeaturedSlot() {
  const [isExpanded, setIsExpanded] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    // 检查URL中是否有Twitter的授权码
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    
    if (code) {
      // 存储Twitter授权状态
      localStorage.setItem('twitter_connected', 'true');
      // 清除URL中的授权码
      window.history.replaceState({}, document.title, window.location.pathname);
      // 跳转到创建页面
      navigate('/create-slot');
    }
  }, [navigate]);

  const handleSlotClick = () => {
    navigate('/slot/meow');
  };

  const handleCreateSlot = () => {
    // 直接跳转到创建页面，不需要 Twitter 认证
    navigate('/create-slot');
  };

  return (
    <div className="mt-0 mb-12">
      <div className="text-center mb-3">
        <button 
          onClick={handleCreateSlot}
          className="bg-white text-black px-6 py-1.5 rounded-md font-hanalei text-lg border-2 border-black shadow-[4px_4px_0px_0px_rgba(0,0,0,1)] hover:shadow-[2px_2px_0px_0px_rgba(0,0,0,1)] hover:translate-x-[2px] hover:translate-y-[2px] transition-all"
        >
          CREATE
        </button>
      </div>
      
      <div className="max-w-[1000px] mx-auto px-4 lg:px-16">
        <div className="flex flex-col lg:flex-row items-start lg:items-center gap-4 w-full lg:w-[650px] lg:ml-[40px] mt-4">
          <h2 className="font-hanalei text-lg shrink-0 text-center lg:text-left w-full lg:w-auto">FEATURED:</h2>
          <div className="flex-1 w-full">
            <div className="bg-gray-200 rounded-xl p-2 lg:p-3 flex flex-col lg:flex-row gap-3 border-2 border-black shadow-[4px_4px_0px_0px_rgba(0,0,0,1)]">
              <img 
                src={toly} 
                alt="toly" 
                className="w-24 h-24 rounded-xl shrink-0 cursor-pointer hover:opacity-80 bg-gray-300 mx-auto lg:mx-0 self-start mt-2"
                onClick={handleSlotClick}
              />
              <div className="flex-1 min-w-0">
                <div className="flex items-center gap-2 mb-2 justify-center lg:justify-start">
                  <h3 className="text-xl font-hanalei cursor-pointer hover:opacity-80" onClick={handleSlotClick}>
                    TOLY
                  </h3>
                  <span className="text-xl">
                    (<span className="font-hanalei">$TOLY</span>)
                  </span>
                  <a 
                    href="https://x.com/aeyakovenko" 
                    target="_blank" 
                    rel="noopener noreferrer"
                    className="hover:opacity-80"
                  >
                    <img src={twitter_logo} alt="X" className="w-5 h-5" />
                  </a>
                </div>
                <p className="text-black text-xs mb-4 text-center lg:text-left">
                  Co-Founder of Solana Labs. Award winning phone creator. NFA, don't trust me, mostly technical gibberish.
                </p>
                
                <div className="grid grid-cols-2 lg:grid-cols-4 gap-3">
                  <div>
                    <p className="text-xs font-hanalei">PRICE PER SLOT</p>
                    <p className="text-base">$1,230</p>
                  </div>
                  <div>
                    <p className="text-xs font-hanalei">FOLLOWERS</p>
                    <p className="text-base">100,789,000</p>
                  </div>
                  <div>
                    <p className="text-xs font-hanalei">HOLDERS</p>
                    <p className="text-base">4,032</p>
                  </div>
                  <div>
                    <p className="text-xs font-hanalei">MARKET CAP</p>
                    <p className="text-base">$896.85K</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FeaturedSlot 