import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import LoginModal from './LoginModal';

function Navbar() {
  const [isConnected, setIsConnected] = useState(false);
  const [walletAddress, setWalletAddress] = useState('');
  const [walletBalance, setWalletBalance] = useState(0);
  const [showToast, setShowToast] = useState(false);
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const [twitterUser, setTwitterUser] = useState(null);
  const [showDropdown, setShowDropdown] = useState(false);

  // Twitter OAuth 配置
  const twitterConfig = {
    clientId: "NHItdTJud1RFMTNIXy0wYVFGV0M6MTpjaQ",  // 直接使用值
    redirectUri: "http://localhost:3000/auth/callback", // 直接使用值
    scope: 'tweet.read users.read',
  };

  // Twitter 登录函数
  const connectX = async () => {
    try {
      // 生成随机 state 用于防止 CSRF 攻击
      const state = Math.random().toString(36).substring(7);
      localStorage.setItem('twitter_oauth_state', state);

      // 构建 OAuth URL
      const authUrl = new URL('https://twitter.com/i/oauth2/authorize');
      // 在构建 URL 前先检查所有参数是否存在
      console.log('Config:', twitterConfig); // 调试用
      
      authUrl.searchParams.append('client_id', twitterConfig.clientId);
      authUrl.searchParams.append('redirect_uri', twitterConfig.redirectUri);
      authUrl.searchParams.append('scope', twitterConfig.scope);
      authUrl.searchParams.append('state', state);
      authUrl.searchParams.append('response_type', 'code');
      authUrl.searchParams.append('code_challenge_method', 'plain');
      authUrl.searchParams.append('code_challenge', state);

      // 调试用：打印完整 URL
      console.log('Auth URL:', authUrl.toString());

      // 重定向到 Twitter 登录页面
      window.location.href = authUrl.toString();
    } catch (error) {
      console.error('Failed to connect Twitter:', error);
    }
  };

  // 处理 Twitter 回调
  useEffect(() => {
    const handleTwitterCallback = async () => {
      const urlParams = new URLSearchParams(window.location.search);
      const code = urlParams.get('code');
      const state = urlParams.get('state');
      const savedState = localStorage.getItem('twitter_oauth_state');

      if (code && state === savedState) {
        try {
          // 从 Twitter API 获取真实用户信息
          const response = await fetch(`https://api.twitter.com/2/users/me`, {
            headers: {
              'Authorization': `Bearer ${code}`,
            }
          });
          const data = await response.json();
          
          const userInfo = {
            name: data.data.name,
            username: `@${data.data.username}`,
            profileImage: data.data.profile_image_url,
          };
          
          localStorage.setItem('twitter_user', JSON.stringify(userInfo));
          setTwitterUser(userInfo);
        } catch (error) {
          console.error('Failed to handle Twitter callback:', error);
        }
      }
    };

    handleTwitterCallback();
  }, []);

  // Check if Phantom wallet is installed
  const getProvider = () => {
    if ('phantom' in window) {
      const provider = window.phantom?.solana;
      if (provider?.isPhantom) {
        return provider;
      }
    }
    // If Phantom wallet is not installed, open installation page
    window.open('https://phantom.app/', '_blank');
  };

  // Check connection status when component loads
  useEffect(() => {
    const walletStatus = localStorage.getItem('walletConnected');
    const savedAddress = localStorage.getItem('walletAddress');
    if (walletStatus === 'true' && savedAddress) {
      setIsConnected(true);
      setWalletAddress(savedAddress);
      // Fetch balance again
      fetchBalance(savedAddress);
    }
  }, []);

  // Get wallet balance
  const fetchBalance = async (address) => {
    try {
      const provider = getProvider();
      if (provider) {
        const balance = await provider.request({
          method: 'getBalance',
          params: [address],
        });
        setWalletBalance(balance / 1000000000); // Convert to SOL
      }
    } catch (error) {
      console.error('Failed to fetch balance:', error);
    }
  };

  // Disconnect wallet
  const handleDisconnect = async () => {
    try {
      const provider = getProvider();
      if (provider) {
        // Disconnect
        await provider.disconnect();
        // Clear authorization
        await provider.request({
          method: "disconnect"
        });
        
        setIsConnected(false);
        setWalletAddress('');
        setWalletBalance(0);
        localStorage.removeItem('walletConnected');
        localStorage.removeItem('walletAddress');
      }
    } catch (error) {
      console.error('Failed to disconnect:', error);
    }
  };

  // Connect wallet
  const handleConnect = async () => {
    try {
      const provider = getProvider();
      if (provider) {
        // Make sure previous connection is fully disconnected
        try {
          await provider.disconnect();
          await provider.request({
            method: "disconnect"
          });
        } catch (e) {
          // Ignore possible errors
        }
        
        // Reconnect and request authorization
        const resp = await provider.connect();
        const address = resp.publicKey.toString();
        
        setWalletAddress(address);
        setIsConnected(true);
        localStorage.setItem('walletConnected', 'true');
        localStorage.setItem('walletAddress', address);
        
        await fetchBalance(address);

        // Show success toast
        setShowToast(true);
        // Hide toast after 3 seconds
        setTimeout(() => {
          setShowToast(false);
        }, 3000);
      }
    } catch (error) {
      console.error('Failed to connect wallet:', error);
    }
  };

  // Format wallet address display
  const formatAddress = (address) => {
    if (!address) return '';
    return `${address.slice(0, 4)}...${address.slice(-4)}`;
  };

  const handleLogin = (type) => {
    if (type === 'wallet') {
      handleConnect();
    } else if (type === 'x') {
      connectX();
    }
    setIsLoginModalOpen(false);
  };

  // 初始化时检查用户登录状态
  useEffect(() => {
    const savedTwitterUser = localStorage.getItem('twitter_user');
    if (savedTwitterUser) {
      setTwitterUser(JSON.parse(savedTwitterUser));
    }
  }, []);

  const handleDisconnectTwitter = () => {
    setTwitterUser(null);
    localStorage.removeItem('twitter_user');
    localStorage.removeItem('twitter_oauth_state');
    setShowDropdown(false);
  };

  // 处理点击外部关闭下拉菜单
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (showDropdown && !event.target.closest('.dropdown-container')) {
        setShowDropdown(false);
      }
    };
    
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showDropdown]);

  return (
    <>
      {/* Success toast - floating display */}
      {showToast && (
        <div className="fixed top-20 left-1/2 transform -translate-x-1/2 z-50">
          <div className="bg-white shadow-lg border rounded-lg px-8 py-3">
            <p className="text-green-600 text-center font-medium">
              Wallet connected successfully!
            </p>
          </div>
        </div>
      )}
      
      <nav className="bg-white border-b border-gray-400">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between items-center h-16">
            <div className="flex-shrink-0">
              <Link to="/">
                <h1 className="text-4xl font-bold hover:text-gray-700 font-hanalei">Tout.club</h1>
              </Link>
            </div>
            {twitterUser ? (
              // 显示 Twitter 用户信息
              <div className="relative dropdown-container">
                <button
                  className="flex items-center gap-2 hover:opacity-80"
                  onClick={() => setShowDropdown(!showDropdown)}
                >
                  <img 
                    src={twitterUser.profileImage} 
                    alt={twitterUser.name}
                    className="w-8 h-8 rounded-full"
                  />
                  <span className="font-hanalei">{twitterUser.username}</span>
                </button>
                
                {/* 下拉菜单 */}
                {showDropdown && (
                  <div className="absolute right-0 mt-2 w-48 bg-white rounded-lg shadow-lg border-2 border-black py-1">
                    <button
                      onClick={handleDisconnectTwitter}
                      className="w-full px-4 py-2 text-left hover:bg-gray-100 font-hanalei text-red-500"
                    >
                      Disconnect Twitter
                    </button>
                  </div>
                )}
              </div>
            ) : isConnected ? (
              // 显示钱包地址
              <button 
                onClick={handleDisconnect}
                className="bg-white text-black px-6 py-2 rounded-md font-hanalei border-2 border-black shadow-[4px_4px_0px_0px_rgba(0,0,0,1)] hover:shadow-[2px_2px_0px_0px_rgba(0,0,0,1)] hover:translate-x-[2px] hover:translate-y-[2px] transition-all"
              >
                {formatAddress(walletAddress)}
              </button>
            ) : (
              <button 
                onClick={() => setIsLoginModalOpen(true)}
                className="bg-white text-black px-6 py-2 rounded-md font-hanalei border-2 border-black shadow-[4px_4px_0px_0px_rgba(0,0,0,1)] hover:shadow-[2px_2px_0px_0px_rgba(0,0,0,1)] hover:translate-x-[2px] hover:translate-y-[2px] transition-all"
              >
                Log in
              </button>
            )}
          </div>
        </div>
      </nav>

      <LoginModal 
        isOpen={isLoginModalOpen}
        onClose={() => setIsLoginModalOpen(false)}
        onLogin={handleLogin}
      />
    </>
  );
}

export default Navbar; 