import { useState } from 'react';
import meow from '../assets/images/meow.png';

function SlotList() {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [sortBy, setSortBy] = useState('rank');
  const [sortOrder, setSortOrder] = useState('asc');

  const slots = [
    {
      id: 1,
      rank: 1,
      name: 'D.J.Trump',
      ticker: 'TRUMP',
      image: meow,
      price: 1613,
      holders: 4613,
      volume24h: 568300,
      marketCap: 25700000,
    },
    {
      id: 2,
      rank: 2,
      name: 'CryptoWhale',
      ticker: 'Whale',
      image: meow,
      price: 4250,
      holders: 436,
      volume24h: 425000,
      marketCap: 1850000,
    },
    {
      id: 3,
      rank: 3,
      name: 'QW',
      ticker: 'Qiao',
      image: meow,
      price: 3390,
      holders: 416,
      volume24h: 339000,
      marketCap: 1620000,
    },
    {
      rank: 4,
      name: 'Imran',
      ticker: 'Imran',
      image: meow,
      price: 3560,
      holders: 407,
      volume24h: 356000,
      marketCap: 1450000,
    },
    {
      rank: 5,
      name: 'Memegud',
      ticker: 'Mgud',
      image: meow,
      price: 3240,
      holders: 395,
      volume24h: 324000,
      marketCap: 1280000,
    },
    {
      rank: 6,
      name: 'Blockchain Dev',
      ticker: 'BDev',
      image: meow,
      price: 2980,
      holders: 376,
      volume24h: 298000,
      marketCap: 1120000,
    },
    {
      rank: 7,
      name: '0xHulius',
      ticker: 'Hulius',
      image: meow,
      price: 2760,
      holders: 355,
      volume24h: 276000,
      marketCap: 980000,
    },
    {
      rank: 8,
      name: 'Wallstreetbets',
      ticker: 'WSB',
      image: meow,
      price: 2540,
      holders: 338,
      volume24h: 254000,
      marketCap: 860000,
    },
    {
      rank: 9,
      name: 'SolWizard',
      ticker: 'Wizard',
      image: meow,
      price: 2320,
      holders: 323,
      volume24h: 232000,
      marketCap: 750000,
    },
    {
      rank: 10,
      name: 'Watcher',
      ticker: 'Watch',
      image: meow,
      price: 2180,
      holders: 312,
      volume24h: 218000,
      marketCap: 680000,
    }
  ];

  const sortOptions = [
    { label: 'Rank', value: 'rank' },
    { label: 'Price', value: 'price' },
    { label: 'Holders', value: 'holders' },
    { label: 'Market Cap', value: 'marketCap' }
  ];

  const handleSort = (field) => {
    if (sortBy === field) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortBy(field);
      setSortOrder('asc');
    }
    setIsDropdownOpen(false);
  };

  const sortedSlots = [...slots].sort((a, b) => {
    const direction = sortOrder === 'asc' ? 1 : -1;
    
    if (sortBy === 'rank') {
      return (a.rank - b.rank) * direction;
    }
    if (sortBy === 'price') {
      return b.price - a.price;
    }
    if (sortBy === 'holders') {
      return b.holders - a.holders;
    }
    if (sortBy === 'marketCap') {
      return b.marketCap - a.marketCap;
    }
    return 0;
  });

  return (
    <div className="w-full max-w-[1200px] mx-auto px-4 sm:px-16">
      <div className="flex flex-col sm:flex-row justify-between mb-4 sm:mb-6 gap-4 sm:gap-8">
        <div className="relative flex-1">
          <input
            type="text"
            placeholder="search by"
            className="w-full pl-10 pr-4 py-2 sm:py-3 border-2 border-black rounded-lg font-hanalei text-base sm:text-lg"
          />
          <svg 
            className="w-6 h-6 absolute left-3 top-1/2 transform -translate-y-1/2" 
            fill="none" 
            stroke="currentColor" 
            viewBox="0 0 24 24"
          >
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
          </svg>
        </div>
        
        <div className="relative w-full sm:w-auto">
          <button 
            className="w-full sm:w-auto border-2 border-black rounded-lg px-4 sm:px-6 h-[45px] sm:h-[55px] font-hanalei flex items-center justify-between sm:justify-start gap-2"
            onClick={() => setIsDropdownOpen(!isDropdownOpen)}
          >
            sort by
            <svg 
              className={`w-4 h-4 transform transition-transform ${isDropdownOpen ? 'rotate-180' : ''}`} 
              fill="none" 
              stroke="currentColor" 
              viewBox="0 0 24 24"
            >
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
            </svg>
          </button>

          {isDropdownOpen && (
            <div className="absolute right-0 mt-2 w-48 bg-white border-2 border-black rounded-lg shadow-lg z-10">
              {sortOptions.map(({ label, value }) => (
                <button
                  key={value}
                  onClick={() => handleSort(value)}
                  className={`w-full px-4 py-2 text-left font-hanalei hover:bg-gray-100 flex items-center justify-between ${
                    sortBy === value ? 'bg-gray-100' : ''
                  }`}
                >
                  {label}
                  {sortBy === value && (
                    <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>
                  )}
                </button>
              ))}
            </div>
          )}
        </div>
      </div>

      <div className="block sm:hidden">
        {sortedSlots.map((slot) => (
          <div key={slot.rank} className="bg-white rounded-lg border border-gray-200 p-4 mb-4">
            <div className="flex items-center gap-6">
              <span className="text-gray-500">{slot.rank}</span>
              <div className="flex items-center gap-3">
                <img src={slot.image} alt={slot.name} className="w-10 h-10 rounded-full" />
                <div>
                  <div className="flex items-center">
                    <span className="font-medium">{slot.name}</span>
                    <span className="font-medium ml-2">($</span>{slot.ticker}<span>)</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="grid grid-cols-2 gap-2 text-sm">
              <div>
                <div className="text-gray-500">Price</div>
                <div>${slot.price}</div>
              </div>
              <div>
                <div className="text-gray-500">Holders</div>
                <div>{slot.holders}</div>
              </div>
              <div>
                <div className="text-gray-500">24h Vol</div>
                <div>${(slot.volume24h / 1000).toFixed(1)}K</div>
              </div>
              <div>
                <div className="text-gray-500">Market Cap</div>
                <div>${(slot.marketCap / 1000000).toFixed(2)}M</div>
              </div>
            </div>
          </div>
        ))}
      </div>

      <table className="hidden sm:table w-full">
        <thead>
          <tr className="text-left border-b-2 border-black">
            <th className="py-4 w-[5%] font-hanalei">#</th>
            <th className="py-4 w-[35%] font-hanalei">NAME</th>
            <th className="py-4 w-[15%] font-hanalei">PRICE</th>
            <th className="py-4 w-[15%] font-hanalei">HOLDERS</th>
            <th className="py-4 w-[15%] font-hanalei">24H VOL</th>
            <th className="py-4 w-[15%] font-hanalei">MARKET CAP</th>
          </tr>
        </thead>
        <tbody>
          {sortedSlots.map((slot) => (
            <tr key={slot.rank} className="border-b border-gray-200">
              <td className="py-4">{slot.rank}</td>
              <td className="py-4">
                <div className="flex items-center gap-3">
                  <img src={slot.image} alt={slot.name} className="w-10 h-10 rounded-full bg-gray-300" />
                  <div className="flex items-center">
                    <span className="font-medium">{slot.name}</span>
                    <span className="font-medium ml-2">($</span>{slot.ticker}<span>)</span>
                  </div>
                </div>
              </td>
              <td className="py-4">${slot.price}</td>
              <td className="py-4">{slot.holders}</td>
              <td className="py-4">${(slot.volume24h / 1000).toFixed(1)}K</td>
              <td className="py-4">${(slot.marketCap / 1000000).toFixed(2)}M</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default SlotList; 