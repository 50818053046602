import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import Layout from './components/Layout';
import Home from './pages/Home';
import SlotDetail from './pages/SlotDetail';
import Navbar from './components/Navbar'
import FeaturedSlot from './components/FeaturedSlot'
import SlotList from './components/SlotList'
import CreateSlot from './components/CreateSlot'
import Profile from './components/Profile'
import { useEffect } from 'react';

function App() {
  return (
    <Router>
      <Layout>
        <Navbar />
        <Routes>
          <Route path="/" element={
            <main className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
              <FeaturedSlot />
              <SlotList />
            </main>
          } />
          <Route path="/slot/:slotId" element={<SlotDetail />} />
          <Route path="/create-slot" element={<CreateSlot />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/auth/callback" element={<TwitterCallback />} />
        </Routes>
      </Layout>
    </Router>
  )
}

// Twitter 回调处理组件
function TwitterCallback() {
  const navigate = useNavigate();

  useEffect(() => {
    const handleCallback = async () => {
      const urlParams = new URLSearchParams(window.location.search);
      const code = urlParams.get('code');
      const state = urlParams.get('state');
      const savedState = localStorage.getItem('twitter_oauth_state');

      if (code && state === savedState) {
        try {
          // 从 Twitter API 获取真实用户信息
          const response = await fetch(`https://api.twitter.com/2/users/me`, {
            headers: {
              'Authorization': `Bearer ${code}`,
            }
          });
          const data = await response.json();
          
          const userInfo = {
            name: data.data.name,
            username: `@${data.data.username}`,
            profileImage: data.data.profile_image_url,
          };
          
          localStorage.setItem('twitter_user', JSON.stringify(userInfo));
          navigate('/');
        } catch (error) {
          console.error('Failed to handle Twitter callback:', error);
          navigate('/');
        }
      }
    };

    handleCallback();
  }, [navigate]);

  return <div>Processing...</div>;
}

export default App 