import { useEffect, useState } from 'react';

function GlobalMarquee() {
  const [notifications] = useState([
    "Alex consumed 1 slot to book Meow's post",
    "CryptoWhale spent $25,000 for 3 Jack's slots",
    "Emma's slot price spiked by 350% in 24h",
  ]);

  return (
    <div className="bg-black py-2 overflow-hidden">
      <div className="marquee-container">
        <div className="animate-marquee whitespace-nowrap">
          {notifications.map((text, index) => (
            <span key={index} className="text-white mx-8">
              <span className="text-yellow-400">•</span> {text}
            </span>
          ))}
          {/* 重复一次确保无缝滚动 */}
          {notifications.map((text, index) => (
            <span key={`repeat-${index}`} className="text-white mx-8">
              <span className="text-yellow-400">•</span> {text}
            </span>
          ))}
        </div>
      </div>
    </div>
  );
}

export default GlobalMarquee; 