import GlobalMarquee from './GlobalMarquee';

function Layout({ children }) {
  return (
    <div className="flex flex-col min-h-screen">
      <GlobalMarquee />
      <div className="flex-1 border-t-2 border-gray-800">
        {children}
      </div>
    </div>
  );
}

export default Layout; 