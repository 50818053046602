import { useState } from 'react';
import toly from '../assets/images/toly.jpg';
import twitter from '../assets/images/twitter.png';

function Profile() {
  const [activeTab, setActiveTab] = useState('HOLDINGS');
  const [expandedItem, setExpandedItem] = useState(null);

  const slotRequests = [
    {
      id: 1,
      wallet: 'Drwv...Tn4e,Hy69...zCpq,...',
      time: '(Time and date)',
      content: 'Solana is the fastest chain!',
      status: 'pending'
    },
    {
      id: 2,
      wallet: 'Drwv...Tn4e,Hy69...zCpq,...',
      time: '(Time and date)',
      content: 'Another message here',
      status: 'pending'
    },
    {
      id: 3,
      wallet: 'Drwv...Tn4e,Hy69...zCpq,...',
      time: '(Time and date)',
      content: 'Third message content',
      status: 'pending'
    }
  ];

  return (
    <div className="max-w-[1000px] mx-auto px-4 lg:px-16 pt-2">
      <h1 className="text-3xl font-hanalei mb-4">MY PROFILE</h1>

      {/* 个人信息卡片 */}
      <div className="bg-gray-200 rounded-xl p-4 lg:p-6 border-2 border-black">
        <div className="flex flex-col lg:flex-row gap-4 lg:gap-6 mb-6">
          <img src={toly} alt="Profile" className="w-32 h-32 rounded-xl mx-auto lg:mx-0" />
          <div className="flex-1">
            <div className="flex items-center gap-2 mb-2 justify-center lg:justify-start">
              <h2 className="text-2xl font-hanalei">TOLY</h2>
              <img src={twitter} alt="Twitter" className="w-6 h-6" />
            </div>
            <p className="text-gray-800 text-center lg:text-left">
              Co-Founder of Solana Labs. Award winning phone creator. NFA, don't trust me, mostly technical gibberish.
            </p>
          </div>
        </div>

        <div className="grid grid-cols-2 lg:grid-cols-4 gap-4">
          <div className="text-center lg:text-left">
            <p className="text-sm font-hanalei whitespace-nowrap">PRICE PER SLOT</p>
            <p className="text-xl">$1,230</p>
          </div>
          <div className="text-center lg:text-left">
            <p className="text-sm font-hanalei">FOLLOWERS</p>
            <p className="text-xl">100,789,000</p>
          </div>
          <div className="text-center lg:text-left">
            <p className="text-sm font-hanalei">HOLDERS</p>
            <p className="text-xl">4,032</p>
          </div>
          <div className="text-center lg:text-left">
            <p className="text-sm font-hanalei whitespace-nowrap">AVAILABLE SLOTS</p>
            <p className="text-xl">2</p>
          </div>
        </div>
      </div>

      {/* 标签切换 */}
      <div className="flex mt-6">
        <button
          className={`px-4 lg:px-6 py-2 font-hanalei ${
            activeTab === 'HOLDINGS' ? 'bg-gray-200 rounded-t-lg border-2 border-b-0 border-black' : ''
          }`}
          onClick={() => setActiveTab('HOLDINGS')}
        >
          HOLDINGS
        </button>
        <button
          className={`px-4 lg:px-6 py-2 font-hanalei relative ${
            activeTab === 'MY SLOT' ? 'bg-gray-200 rounded-t-lg border-2 border-b-0 border-black' : ''
          }`}
          onClick={() => setActiveTab('MY SLOT')}
        >
          MY SLOT
          <span className="absolute -top-1 -right-1 w-3 h-3 bg-red-500 rounded-full"></span>
        </button>
      </div>

      {/* 内容区域 */}
      <div className="bg-gray-200 border-2 border-black rounded-b-xl rounded-tr-xl">
        {activeTab === 'HOLDINGS' && (
          <div className="p-4 max-h-[500px] overflow-x-auto">
            <table className="w-full min-w-[800px]">
              <thead>
                <tr className="text-left">
                  <th className="py-2 px-4 font-hanalei">Name</th>
                  <th className="py-2 px-4 font-hanalei">Price</th>
                  <th className="py-2 px-4 font-hanalei">Balance</th>
                  <th className="py-2 px-4 font-hanalei">PnL</th>
                  <th className="py-2 px-4 font-hanalei">24h Vol</th>
                  <th className="py-2 px-4 font-hanalei">Market cap</th>
                </tr>
              </thead>
              <tbody>
                <tr className="border-t border-gray-300">
                  <td className="py-4 px-4">
                    <div className="flex items-center gap-2">
                      <img src={toly} alt="" className="w-8 h-8 rounded-full" />
                      <span>D.J.Trump</span>
                    </div>
                  </td>
                  <td className="py-4 px-4">$1613</td>
                  <td className="py-4 px-4">3.52 ($5680)</td>
                  <td className="py-4 px-4 text-green-500">+$970</td>
                  <td className="py-4 px-4">$568.3K</td>
                  <td className="py-4 px-4">$25.7m</td>
                </tr>
                <tr className="border-t border-gray-300">
                  <td className="py-4 px-4">
                    <div className="flex items-center gap-2">
                      <img src={toly} alt="" className="w-8 h-8 rounded-full" />
                      <span>0xmert</span>
                    </div>
                  </td>
                  <td className="py-4 px-4">$1425.3</td>
                  <td className="py-4 px-4">2.56 ($3650)</td>
                  <td className="py-4 px-4 text-green-500">+$1330</td>
                  <td className="py-4 px-4">$363K</td>
                  <td className="py-4 px-4">$896.85K</td>
                </tr>
              </tbody>
            </table>
          </div>
        )}

        {activeTab === 'MY SLOT' && (
          <div className="p-4 space-y-2 max-h-[500px] overflow-y-auto">
            {slotRequests.map((request, index) => (
              <div key={request.id} className="bg-gray-100 rounded-lg">
                <div 
                  className="p-4 flex flex-col lg:flex-row justify-between items-start lg:items-center gap-2 cursor-pointer"
                  onClick={() => setExpandedItem(expandedItem === request.id ? null : request.id)}
                >
                  <div className="font-hanalei break-words">
                    {request.wallet} just booked your slot of {request.time}
                  </div>
                  <svg 
                    className={`w-6 h-6 transform transition-transform ${expandedItem === request.id ? 'rotate-180' : ''}`}
                    fill="none" 
                    stroke="currentColor" 
                    viewBox="0 0 24 24"
                  >
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                  </svg>
                </div>
                
                {expandedItem === request.id && (
                  <div className="p-4 border-t border-gray-300">
                    <div className="flex flex-col lg:flex-row justify-between items-start lg:items-center gap-4 mb-4">
                      <div className="flex items-center gap-2">
                        <span className="font-hanalei break-words">{request.wallet.split(',')[0]}</span>
                      </div>
                      <div className="flex gap-2 w-full lg:w-auto">
                        <button className="flex-1 lg:flex-none px-4 py-2 bg-red-400 text-white rounded-lg font-hanalei">
                          Approve
                        </button>
                        <button className="flex-1 lg:flex-none px-4 py-2 bg-green-400 text-white rounded-lg font-hanalei">
                          Reject
                        </button>
                      </div>
                    </div>
                    <div className="bg-white rounded-lg p-4">
                      <p className="break-words">{request.content}</p>
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}

export default Profile; 