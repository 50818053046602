import { useState, useRef } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import checkbox from '../assets/images/checkbox.svg';
import upload from '../assets/images/upload.svg'

function CreateSlot() {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [hours, setHours] = useState('09');
  const [minutes, setMinutes] = useState('00');
  const [openNextTime, setOpenNextTime] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);
  const fileInputRef = useRef(null);

  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    if (file && file.type.startsWith('image/')) {
      setSelectedFile(file);
      // 创建预览URL
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewUrl(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleUploadClick = () => {
    fileInputRef.current.click();
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    if (file && file.type.startsWith('image/')) {
      setSelectedFile(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewUrl(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  // 验证并格式化小时输入
  const handleHoursChange = (e) => {
    let value = e.target.value.replace(/\D/g, ''); // 只允许数字
    if (value === '') {
      setHours(value);
      return;
    }
    value = parseInt(value);
    if (value >= 0 && value <= 23) {
      setHours(value.toString().padStart(2, '0'));
    }
  };

  // 验证并格式化分钟输入
  const handleMinutesChange = (e) => {
    let value = e.target.value.replace(/\D/g, ''); // 只允许数字
    if (value === '') {
      setMinutes(value);
      return;
    }
    value = parseInt(value);
    if (value >= 0 && value <= 59) {
      setMinutes(value.toString().padStart(2, '0'));
    }
  };

  return (
    <div className="max-w-[1000px] mx-auto px-4 sm:px-16 py-4 sm:py-8">
      <h1 className="text-2xl sm:text-3xl font-hanalei text-center mb-6 sm:mb-8">CREATE SLOT</h1>
      
      <div className="w-full sm:w-[800px] mx-auto">
        <div className="flex flex-col sm:flex-row justify-center gap-4">
          <div 
            className="w-full sm:w-[265px] h-[265px] bg-gray-200 rounded-xl flex flex-col items-center justify-center border-2 border-black cursor-pointer hover:bg-gray-300"
            onClick={handleUploadClick}
            onDragOver={handleDragOver}
            onDrop={handleDrop}
          >
            {previewUrl ? (
              <div className="relative w-full h-full">
                <img 
                  src={previewUrl} 
                  alt="Preview" 
                  className="w-full h-full object-cover rounded-xl"
                />
                <button 
                  className="absolute top-2 right-2 bg-red-500 text-white rounded-full w-6 h-6 flex items-center justify-center"
                  onClick={(e) => {
                    e.stopPropagation();
                    setSelectedFile(null);
                    setPreviewUrl(null);
                  }}
                >
                  ×
                </button>
              </div>
            ) : (
              <>
                <div className="w-10 h-10 mb-3">
                  <img src={upload} alt="upload" className="w-full h-full" />
                </div>
                <p className="font-hanalei text-base">SELECT FILE</p>
                <p className="text-gray-400 text-xs mt-2 font-hanalei">
                  Support JPG, PNG, etc.
                </p>
              </>
            )}
            <input
              type="file"
              ref={fileInputRef}
              className="hidden"
              accept="image/*"
              onChange={handleFileSelect}
            />
          </div>

          <div className="w-full sm:w-[450px] space-y-4">
            <input 
              type="text"
              placeholder="Name"
              className="w-full px-4 py-3 rounded-lg bg-gray-200 border-2 border-black font-hanalei"
            />
            <input 
              type="text"
              placeholder="Ticker"
              className="w-full px-4 py-3 rounded-lg bg-gray-200 border-2 border-black font-hanalei"
            />
            <textarea 
              placeholder="Description (Optional)"
              className="w-full px-4 py-3 rounded-lg bg-gray-200 border-2 border-black font-hanalei resize-none h-32"
            />
          </div>
        </div>
      </div>

      {/* 日历区域 */}
      <div className="mt-12 flex flex-col items-center">
        <div className="w-[800px] mb-8">
          <h2 className="font-hanalei text-2xl text-center mb-6">SELECT AVAILABLE SLOTS</h2>
          
          <div className="bg-white rounded-xl border-2 border-black px-0.5 py-2 w-[320px] mx-auto">
            {/* 日历 */}
            <div className="custom-calendar mb-0 scale-[0.82] origin-top">
              <Calendar
                onChange={setSelectedDate}
                value={selectedDate}
                minDate={new Date()}
                maxDate={new Date(new Date().setFullYear(new Date().getFullYear() + 1))}
                className="border-none bg-transparent calendar-compact"
                tileClassName="font-mono"
                locale="en-US"
                navigationLabel={({ date }) => {
                  return date.toLocaleString('en-US', { 
                    year: 'numeric',
                    month: 'short',
                  }).toUpperCase();
                }}
                formatShortWeekday={(locale, date) => {
                  return date.toLocaleString('en-US', { 
                    weekday: 'short'
                  }).toUpperCase();
                }}
                formatMonth={(locale, date) => {
                  return date.toLocaleString('en-US', { 
                    year: 'numeric',
                    month: 'short'
                  }).toUpperCase();
                }}
                prevLabel="PREV"
                nextLabel="NEXT"
                prev2Label={null}
                next2Label={null}
                showNeighboringMonth={false}
                view="month"
                minDetail="month"
                maxDetail="month"
              />
            </div>

            {/* 时间选择 */}
            <div className="mt-[-25px]">
              <h3 className="font-hanalei text-base mb-1 text-center">SELECT TIME</h3>
              <div className="flex justify-center items-center gap-4">
                <input
                  type="number"
                  value={hours}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value === '' || (parseInt(value) >= 0 && parseInt(value) <= 23)) {
                      setHours(value);
                    }
                  }}
                  onBlur={() => {
                    if (hours !== '') {
                      const value = parseInt(hours);
                      setHours(value.toString().padStart(2, '0'));
                    }
                  }}
                  min="0"
                  max="23"
                  placeholder="00"
                  className="w-14 px-2 py-0.5 rounded-lg bg-gray-100 border-2 border-black font-mono text-center text-base"
                />

                <span className="font-mono text-base">:</span>

                <input
                  type="number"
                  value={minutes}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value === '' || (parseInt(value) >= 0 && parseInt(value) <= 59)) {
                      setMinutes(value);
                    }
                  }}
                  onBlur={() => {
                    if (minutes !== '') {
                      const value = parseInt(minutes);
                      setMinutes(value.toString().padStart(2, '0'));
                    }
                  }}
                  min="0"
                  max="59"
                  placeholder="00"
                  className="w-14 px-2 py-0.5 rounded-lg bg-gray-100 border-2 border-black font-mono text-center text-base"
                />
              </div>
            </div>
          </div>
        </div>

        {/* 复选框和文字 */}
        <div className="mt-4 flex items-center gap-2 justify-center">
          <button 
            className="w-6 h-6 border-2 border-black rounded flex items-center justify-center bg-white"
            onClick={() => setOpenNextTime(!openNextTime)}
          >
            {openNextTime && <img src={checkbox} alt="checked" className="w-4 h-4" />}
          </button>
          <span className="font-hanalei text-center">I'll open next time</span>
        </div>
      </div>

      {/* Create 按钮 */}
      <div className="flex justify-center mt-8">
        <button className="bg-white text-black px-8 py-2 rounded-md font-hanalei text-xl border-2 border-black shadow-[4px_4px_0px_0px_rgba(0,0,0,1)] hover:shadow-[2px_2px_0px_0px_rgba(0,0,0,1)] hover:translate-x-[2px] hover:translate-y-[2px] transition-all">
          CREATE
        </button>
      </div>
    </div>
  );
}

export default CreateSlot; 