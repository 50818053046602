import { useState } from 'react';
import x from '../assets/images/twitter_logo.svg'
import phantom from '../assets/images/phantom.jpg';

function LoginModal({ isOpen, onClose, onLogin }) {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-xl p-8 w-[400px] relative">
        {/* 关闭按钮 */}
        <button 
          onClick={onClose}
          className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
        >
          ×
        </button>

        <div className="space-y-6">
          {/* X 登录按钮 */}
          <button 
            className="w-full py-3 px-4 border-2 border-black rounded-lg font-hanalei flex items-center justify-center gap-3 hover:bg-gray-50"
            onClick={() => onLogin('x')}
          >
            <img src={x} alt="X" className="w-6 h-6" />
            <span>Connect X</span>
          </button>

          {/* 钱包连接按钮 */}
          <button 
            className="w-full py-3 px-4 border-2 border-black rounded-lg font-hanalei flex items-center justify-center gap-3 hover:bg-gray-50"
            onClick={() => onLogin('wallet')}
          >
            <img src={phantom} alt="Wallet" className="w-6 h-6" />
            <span>Connect Wallet</span>
          </button>
        </div>
      </div>
    </div>
  );
}

export default LoginModal; 