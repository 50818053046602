import { useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import toly from '../assets/images/toly.jpg';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import twitter_logo from '../assets/images/twitter.png';

function SlotDetail() {
  const { slotId } = useParams();
  const [selectedTab, setSelectedTab] = useState('USD');
  const [selectedTimeframe, setSelectedTimeframe] = useState('1d');
  const [selectedSection, setSelectedSection] = useState('Activity');
  const [rightSection, setRightSection] = useState('Trade');
  const [tradeType, setTradeType] = useState('Buy');
  const [showCalendar, setShowCalendar] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);
  const [hours, setHours] = useState('09');
  const [minutes, setMinutes] = useState('00');
  const [amount, setAmount] = useState('1');

  useEffect(() => {
    // 创建 TradingView Widget
    const script = document.createElement('script');
    script.src = 'https://s3.tradingview.com/tv.js';
    script.async = true;
    script.onload = () => {
      new window.TradingView.widget({
        width: '100%',
        height: 400,
        symbol: "NASDAQ:AAPL",
        interval: "D",
        timezone: "Etc/UTC",
        theme: "light",
        style: "1",
        locale: "en",
        toolbar_bg: "#f1f3f6",
        enable_publishing: false,
        hide_top_toolbar: true,
        hide_legend: true,
        container_id: "tradingview_chart",
        studies: [
          {
            id: "MASimple@tv-basicstudies",
            inputs: {
              length: 20,
              color: "#000000"
            }
          }
        ],
        overrides: {
          "mainSeriesProperties.candleStyle.upColor": "#000000",
          "mainSeriesProperties.candleStyle.downColor": "#000000",
          "mainSeriesProperties.candleStyle.borderUpColor": "#000000",
          "mainSeriesProperties.candleStyle.borderDownColor": "#000000",
          "mainSeriesProperties.candleStyle.wickUpColor": "#000000",
          "mainSeriesProperties.candleStyle.wickDownColor": "#000000"
        }
      });
    };
    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, []);

  const handleDateSelect = (date) => {
    setSelectedDate(date);
    setShowCalendar(false);
    setSelectedTime(`${hours}:${minutes}`);
  };

  const formatSelectedDateTime = () => {
    if (!selectedDate) return 'PICK A DATE';
    const date = selectedDate.toLocaleDateString('en-US', {
      month: 'short',
      day: 'numeric',
      year: 'numeric'
    });
    return `${date} ${selectedTime}`;
  };

  const handleAmountChange = (e) => {
    const value = e.target.value;
    // 允许输入小数，最多支持4位小数
    if (/^\d*\.?\d{0,4}$/.test(value) || value === '') {
      setAmount(value);
    }
  };

  const handleIncrement = () => {
    const currentAmount = parseFloat(amount) || 0;
    setAmount((currentAmount + 1).toFixed(4));
  };

  const handleDecrement = () => {
    const currentAmount = parseFloat(amount) || 0;
    if (currentAmount > 0) {
      const newAmount = Math.max(0, currentAmount - 1);
      setAmount(newAmount.toFixed(4));
    }
  };

  // 计算总价格的函数
  const calculateTotal = () => {
    const currentAmount = parseFloat(amount) || 0;
    const solPrice = (38.25 * currentAmount).toFixed(2);
    const usdPrice = (8269 * currentAmount).toFixed(2);
    return {
      sol: solPrice,
      usd: usdPrice
    };
  };

  // 修改 activityData 数据
  const activityData = [
    {
      id: 1,
      user: { name: 'mismatchingape', avatar: toly },
      type: 'Sell',
      amount: '2.5',
      totalUSD: '$131',
      time: '1 day ago'
    },
    {
      id: 2,
      user: { name: 'cryptowhale', avatar: toly },
      type: 'Buy',
      amount: '5.0',
      totalUSD: '$425',
      time: '2 days ago'
    },
    {
      id: 3,
      user: { name: 'defi_guru', avatar: toly },
      type: 'Buy',
      amount: '1.2',
      totalUSD: '$98',
      time: '2 days ago'
    },
    {
      id: 4,
      user: { name: 'web3_master', avatar: toly },
      type: 'Sell',
      amount: '3.8',
      totalUSD: '$312',
      time: '3 days ago'
    },
    {
      id: 5,
      user: { name: 'solana_whale', avatar: toly },
      type: 'Buy',
      amount: '10.0',
      totalUSD: '$826',
      time: '4 days ago'
    }
  ];

  const topHoldersData = [
    {
      id: 1,
      holder: { name: 'cryptowhale', avatar: toly },
      balance: '156.8',
      owned: '15.6%'
    },
    {
      id: 2,
      holder: { name: 'defi_master', avatar: toly },
      balance: '98.5',
      owned: '9.8%'
    },
    {
      id: 3,
      holder: { name: 'solana_king', avatar: toly },
      balance: '76.3',
      owned: '7.6%'
    },
    {
      id: 4,
      holder: { name: 'web3_investor', avatar: toly },
      balance: '45.2',
      owned: '4.5%'
    },
    {
      id: 5,
      holder: { name: 'nft_collector', avatar: toly },
      balance: '32.1',
      owned: '3.2%'
    }
  ];

  return (
    <div className="max-w-[1400px] mx-auto px-4 sm:px-8 py-4 sm:py-8">
      {/* 头部信息 */}
      <div className="bg-gray-100 rounded-xl p-4 sm:p-6">
        <div className="flex flex-col sm:flex-row gap-4 sm:gap-6">
          <img src={toly} alt="Toly" className="w-24 h-24 sm:w-32 sm:h-32 rounded-xl mx-auto sm:mx-0 self-start" />
          <div className="flex-1">
            <div className="flex items-center gap-2 mb-2 text-center sm:text-left">
              <h1 className="text-xl sm:text-2xl font-hanalei">TOLY</h1>
              <span className="text-xl sm:text-2xl">
                (<span className="font-hanalei">$TOLY</span>)
              </span>
              <a 
                href="https://x.com/aeyakovenko" 
                target="_blank" 
                rel="noopener noreferrer"
                className="hover:opacity-80"
              >
                <img src={twitter_logo} alt="X" className="w-6 h-6" />
              </a>
            </div>
            <p className="text-gray-800 mb-4 sm:mb-6 text-sm sm:text-base">
              Co-Founder of Solana Labs. Award winning phone creator. NFA, don't trust me, mostly technical gibberish.
            </p>
            
            <div className="grid grid-cols-2 sm:grid-cols-5 gap-3 sm:gap-4">
              <div>
                <p className="text-sm font-hanalei">PRICE PER SLOT</p>
                <p className="text-xl">$8,269</p>
              </div>
              <div>
                <p className="text-sm font-hanalei">FOLLOWERS</p>
                <p className="text-xl">632,230</p>
              </div>
              <div>
                <p className="text-sm font-hanalei">HOLDERS</p>
                <p className="text-xl">858</p>
              </div>
              <div>
                <p className="text-sm font-hanalei">AVAILABLE SLOT</p>
                <p className="text-xl">8</p>
              </div>
              <div>
                <p className="text-sm font-hanalei">MARKET CAP</p>
                <p className="text-xl">$7,094,802</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* 主要内容区域 */}
      <div className="grid grid-cols-1 lg:grid-cols-[1fr_400px] gap-8">
        {/* 左侧图表部分 */}
        <div>
          {/* USD/SOL 切换 */}
          <div className="flex gap-2 mb-4">
            <button
              className={`px-4 py-1 rounded font-hanalei border-2 border-black ${
                selectedTab === 'USD' ? 'bg-black text-white' : 'bg-white hover:bg-gray-100'
              }`}
              onClick={() => setSelectedTab('USD')}
            >
              USD
            </button>
            <button
              className={`px-4 py-1 rounded font-hanalei border-2 border-black ${
                selectedTab === 'SOL' ? 'bg-black text-white' : 'bg-white hover:bg-gray-100'
              }`}
              onClick={() => setSelectedTab('SOL')}
            >
              SOL
            </button>
          </div>

          {/* 图表 */}
          <div className="border-2 border-black rounded-xl p-4">
            <div id="tradingview_chart"></div>
          </div>

          {/* 时间范围选择 */}
          <div className="flex gap-4 mt-4">
            {['1h', '4h', '12h', '1d'].map(timeframe => (
              <button
                key={timeframe}
                className={`px-4 py-1 rounded font-hanalei ${
                  selectedTimeframe === timeframe ? 'text-black' : 'text-gray-400'
                }`}
                onClick={() => setSelectedTimeframe(timeframe)}
              >
                {timeframe}
              </button>
            ))}
          </div>
        </div>

        {/* 右侧 Trade 部分 */}
        <div className="mt-5 lg:mt-24 order-2 lg:order-none">
          {/* Trade 表单内容 */}
          <div className="space-y-4">
            {/* 买卖切换按钮 */}
            <div className="flex">
              <button
                className={`flex-1 py-2 font-hanalei border-2 border-black rounded-l-lg ${
                  tradeType === 'Buy' ? 'bg-black text-white' : 'bg-white'
                }`}
                onClick={() => setTradeType('Buy')}
              >
                Buy
              </button>
              <button
                className={`flex-1 py-2 font-hanalei border-2 border-l-0 border-black rounded-r-lg ${
                  tradeType === 'Sell' ? 'bg-black text-white' : 'bg-white'
                }`}
                onClick={() => setTradeType('Sell')}
              >
                Sell
              </button>
            </div>

            <div>
              <p className="text-gray-600 mb-1">Available Balance:</p>
              <p className="text-xl">$0 (SOL)</p>
            </div>

            <div>
              <p className="text-gray-600">Amount (Slot)</p>
              <div className="flex items-center gap-2">
                <button
                  onClick={handleDecrement}
                  className="w-10 h-10 flex items-center justify-center border-2 border-black rounded-lg font-mono text-xl"
                >
                  -
                </button>
                <input
                  type="text"
                  value={amount}
                  onChange={handleAmountChange}
                  className="flex-1 px-4 py-2 text-center text-xl border-2 border-black rounded-lg font-mono"
                  placeholder="0.0000"
                />
                <button
                  onClick={handleIncrement}
                  className="w-10 h-10 flex items-center justify-center border-2 border-black rounded-lg font-mono text-xl"
                >
                  +
                </button>
              </div>
            </div>

            <div>
              <p className="text-gray-600 mb-1">Total {tradeType === 'Buy' ? 'Cost' : 'Receive'}</p>
              <p className="text-xl">
                {calculateTotal().sol} SOL (${calculateTotal().usd})
              </p>
            </div>

            <button className="w-full py-2 bg-white border-2 border-black rounded-lg font-hanalei shadow-[4px_4px_0px_0px_rgba(0,0,0,1)] hover:shadow-[2px_2px_0px_0px_rgba(0,0,0,1)] hover:translate-x-[2px] hover:translate-y-[2px] transition-all">
              Swap
            </button>
          </div>
        </div>
      </div>

      {/* Book a post slot 和 Activity 部分的容器 */}
      <div className="grid grid-cols-1 lg:grid-cols-[1fr_400px] gap-8 mt-12">
        {/* Activity 部分 */}
        <div className="order-3 lg:order-none">
          <div className="flex gap-4 mb-4">
            {['Activity', 'Top Holders'].map(section => (
              <button
                key={section}
                className={`px-4 py-2 font-hanalei ${
                  selectedSection === section ? 'border-b-2 border-black' : ''
                }`}
                onClick={() => setSelectedSection(section)}
              >
                {section}
              </button>
            ))}
          </div>

          {/* Activity 表格 */}
          {selectedSection === 'Activity' && (
            <div className="mt-4">
              <table className="w-full">
                <thead>
                  <tr className="text-left border-b border-gray-200">
                    <th className="py-2 font-hanalei">USER</th>
                    <th className="font-hanalei">TYPE</th>
                    <th className="font-hanalei">AMOUNT</th>
                    <th className="font-hanalei">TOTAL USD</th>
                    <th className="font-hanalei">TIME</th>
                  </tr>
                </thead>
                <tbody>
                  {activityData.map((activity) => (
                    <tr key={activity.id} className="border-b border-gray-100">
                      <td className="py-4 flex items-center gap-2">
                        <img src={activity.user.avatar} alt="" className="w-8 h-8 rounded-full" />
                        <span>{activity.user.name}</span>
                      </td>
                      <td className={activity.type === 'Sell' ? 'text-red-500' : 'text-green-500'}>
                        {activity.type}
                      </td>
                      <td>{activity.amount}</td>
                      <td>{activity.totalUSD}</td>
                      <td>{activity.time}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}

          {/* Top Holders 表格 */}
          {selectedSection === 'Top Holders' && (
            <div className="mt-4">
              <table className="w-full">
                <thead>
                  <tr className="text-left border-b border-gray-200">
                    <th className="py-2 font-hanalei">#</th>
                    <th className="font-hanalei pl-8">HOLDER</th>
                    <th className="font-hanalei">BALANCE</th>
                    <th className="font-hanalei text-right">OWNED</th>
                  </tr>
                </thead>
                <tbody>
                  {topHoldersData.map((holder) => (
                    <tr key={holder.id} className="border-b border-gray-100">
                      <td className="py-4 text-gray-500 font-mono">{holder.id}</td>
                      <td className="pl-8">
                        <div className="flex items-center gap-3">
                          <img src={holder.holder.avatar} alt="" className="w-8 h-8 rounded-full" />
                          <span>{holder.holder.name}</span>
                        </div>
                      </td>
                      <td>${holder.balance}</td>
                      <td className="text-right">{holder.owned}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>

        {/* Book a post slot 部分 */}
        <div className="order-2 lg:order-none">
          <div className="bg-white rounded-xl p-6 border-2 border-black">
            <h2 className="text-xl font-hanalei mb-2">Book a post slot</h2>
            <p className="text-gray-600 mb-4">
              Spend 1 slot and book a post on Toly's X account. Your slot will be refunded if Toly rejects your request
            </p>

            <div className="space-y-4">
              <div>
                <p className="text-gray-600">Your slot balance</p>
                <p className="text-xl">2.56</p>
              </div>

              <div>
                <p className="text-gray-600">Select available slot</p>
                <div className="relative">
                  <button
                    className="w-full px-4 py-3 border-2 border-black rounded-lg font-hanalei text-gray-400 text-left"
                    onClick={() => setShowCalendar(!showCalendar)}
                  >
                    {formatSelectedDateTime()}
                  </button>
                  
                  {showCalendar && (
                    <div className="absolute left-0 mt-2 z-50 bg-white border-2 border-black rounded-xl p-2">
                      <div className="custom-calendar">
                        <Calendar
                          onChange={handleDateSelect}
                          value={selectedDate}
                          minDate={new Date()}
                          maxDate={new Date(new Date().setFullYear(new Date().getFullYear() + 1))}
                          className="border-none bg-transparent calendar-compact"
                          tileClassName="font-mono"
                          locale="en-US"
                          navigationLabel={({ date }) => {
                            return date.toLocaleString('en-US', { 
                              year: 'numeric',
                              month: 'short',
                            }).toUpperCase();
                          }}
                        />
                        <div className="mt-2 flex justify-center items-center gap-2">
                          <input
                            type="number"
                            value={hours}
                            onChange={(e) => {
                              const value = e.target.value;
                              if (value === '' || (parseInt(value) >= 0 && parseInt(value) <= 23)) {
                                setHours(value.padStart(2, '0'));
                                if (selectedDate) setSelectedTime(`${value.padStart(2, '0')}:${minutes}`);
                              }
                            }}
                            min="0"
                            max="23"
                            className="w-14 px-2 py-1 rounded-lg bg-gray-100 border-2 border-black font-mono text-center text-sm"
                          />
                          <span className="font-mono">:</span>
                          <input
                            type="number"
                            value={minutes}
                            onChange={(e) => {
                              const value = e.target.value;
                              if (value === '' || (parseInt(value) >= 0 && parseInt(value) <= 59)) {
                                setMinutes(value.padStart(2, '0'));
                                if (selectedDate) setSelectedTime(`${hours}:${value.padStart(2, '0')}`);
                              }
                            }}
                            min="0"
                            max="59"
                            className="w-14 px-2 py-1 rounded-lg bg-gray-100 border-2 border-black font-mono text-center text-sm"
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div>
                <p className="text-gray-600">Context</p>
                <textarea
                  placeholder="Content you want the creator to post"
                  className="w-full h-32 px-4 py-2 border-2 border-black rounded-lg resize-none font-hanalei"
                />
              </div>

              <button className="w-full py-2 bg-[#B4EDE7] rounded-lg font-hanalei border-2 border-black">
                Book
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SlotDetail;